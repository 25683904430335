//
// user.scss
// Use this to write your custom SCSS
//

body{
    background-color: white;
}



body{
    // background: linear-gradient(to bottom left, rgba(255,255,255,0), rgba(255,255,255,1) 40%), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" stroke="black" stroke-width="0.5" stroke-dasharray="5,5"><path d="M0 0h100v100H0z"/><path d="M0 0h100M0 100H100"/></svg>');
    // background-size: cover, 48px 48px;
}

// fade both sides, top and bottom
// body{
//     background: linear-gradient(to bottom left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" stroke="black" stroke-width="0.5" stroke-dasharray="5,5"><path d="M0 0h100v100H0z"/><path d="M0 0h100M0 100H100"/></svg>');
//     background-size: cover, 30px 30px;
// }

.gradient-text {
    /* Fallback: Set a background color. */
    background-color: #CA4246;
    
    /* Create the gradient. */
     background-image: linear-gradient(
          45deg,
          #CA4246 16.666%, 
          #E16541 16.666%, 
          #E16541 33.333%, 
          #F18F43 33.333%, 
          #F18F43 50%, 
          #8B9862 50%, 
          #8B9862 66.666%, 
          #476098 66.666%, 
          #476098 83.333%, 
          #A7489B 83.333%);
    
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
  
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    
    /* Animate the text when loading the element. */
      /* This animates it on page load and when hovering out. */
      animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
  
  }
  
  .gradient-text:hover{
      animation: rainbow-text-simple-animation 0.5s ease-in forwards;
  }
  
  
  /* Move the background and make it smaller. */
  /* Animation shown when entering the page and after the hover animation. */
  @keyframes rainbow-text-simple-animation-rev {
      0% {
          background-size: 650%;
      }
      40% {
          background-size: 650%;
      }
      100% {
          background-size: 100%;
      }
  }
  
  /* Move the background and make it larger. */
  /* Animation shown when hovering over the text. */
  @keyframes rainbow-text-simple-animation {
      0% {
          background-size: 100%;
      }
      80% {
          background-size: 650%;
      }
      100% {
          background-size: 650%;
      }
  }
    
  
  
//   header {
//     margin-top: 1em;
//     margin-top: calc(50vh - 3em);
//   }
  
//   h1 {
//     font-family: "Archivo Black", sans-serif;
//     font-weight: normal;
//     font-size: 6em;
//     text-align: center;
//     margin-bottom: 0;
//     margin-bottom: -0.25em;
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//     cursor: pointer;
//     width: 605px;
//   }



.highlighter {
    background-image: linear-gradient(transparent 0 50%,#FFDE69 50% 75%,transparent 75%);
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: background-size 420ms,text-decoration 333ms;
    position: relative
}

.highlighter:is(:hover,:focus) {
    text-decoration-thickness: .333rem;
    background-size: 100% 100%;
    border: none
}

.highlighter:active {
    outline: 0
}

.highlighter:after {
    content: "";
    display: block;
    width: 1rem;
    height: 0;
    background-color: #FFDE69;
    position: absolute;
    top: 50%;
    top: calc(50% + 2rem);
    left: -2rem;
    border-radius: 2rem;
    transition: height .45s,top .3s
}

.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}


@keyframes flash {
	0% {
		background-color: #ffff99;
	} /* Yellow */
	100% {
		background-color: transparent;
	}
}

.ListGroupItem-enter {
	animation: flash 1s ease-out;
}


// class for text input to remove all borders and outline
input.no-border {
    border: none;
    outline: none;
    box-shadow: none !important;
    // bottom border 2px
    border-bottom: 2px solid #000;
    transition: none;
    &:focus {
        box-shadow: none !important;
        transition: none;
        outline: none;
    }
}