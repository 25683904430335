//
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;
$prefix: "numeri-";
$prefix: $prefix !default;

$white: #fff !default;
$gray-100: #f1f5f9 !default;
$gray-200: #e2e8f0 !default;
$gray-300: #cbd5e1 !default;
$gray-400: #94a3b8 !default;
$gray-500: #64748b !default;
$gray-600: #475569 !default;
$gray-700: #334155 !default;
$gray-800: #1e293b !default;
$gray-900: #0f172a !default;
$gray-1000: #0b1324 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "black": $black,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
    "1000": $gray-1000,

    "white": $white,
  ),
  $grays
);

$primary: $gray-700 !default;
$secondary: #64748b !default;
$success: #00b8b8 !default;
$info: #0ea5e9 !default;
$warning: #e4bd0b !default;
$danger: #de3d83 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-500;
$text-muted: $gray-500 !default;

  
  @font-face {
    font-family: "VarelaRound";
    src: url("../fonts/VarelaRound-Regular.ttf") format("truetype");
  }
  /* https://fonts.googleapis.com/css2?family=Varela+Round&display=swap */
  
//   body {
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     text-rendering: optimizeLegibility;
//   }
  
  .ais-SearchBox-form::before {
    background: rgba(0, 0, 0, 0)
      url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
      repeat scroll 0% 0%;
    content: "";
    height: 0.96rem;
    left: 1rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 0.96rem;
    display: none;
  }

  .navbar-brand {
    font-size: 1.625rem !important;
    padding-top: 0.4rem !important;
  }

// @import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
// @font-face {
//     font-family: "Helvetica Neue Bold";
//     src: url("../fonts/helvetica-neue-55/HelveticaNeueBold.ttf") format("truetype");
//   }

// import the font from this path: src/assets/fonts/Geist/Geist-Bold.otf
@font-face {
    font-family: "Geist-Bold";
    src: url("../fonts/Geist/Geist-Bold.otf") format("opentype");
  }

  @font-face {
    font-family: "Geist-Mono";
    src: url("../fonts/Geist.Mono/GeistMono-Regular.otf") format("opentype");
  }

// Typography
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Geist-Mono", "sans-serif" !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-heading: "Geist-Bold", "sans-serif" !default;
$font-size-base: 0.875rem !default;

// scss-docs-start headings-variables
$headings-font-family: $font-family-heading !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.5 !default;
// $headings-color: $gray-800 !default;
$headings-color: var(--#{$prefix}headings-color) !default;


// letter spacing
$letter-spacing-sm: -0.04em;
$letter-spacing-xs: -0.08em;
$letter-spacing-base: 0em;
$letter-spacing-md: 0.1em;
$letter-spacing-lg: 0.15em;
$letter-spacing-xl: 0.2em;
$letter-spacing-xxl: 0.25em;
$display-font-sizes: (
  1: 4.5rem,
  2: 3.5rem,
  3: 3rem,
  4: 2.5rem,
  5: 2rem,
  6: 1.5rem,
) !default;


// Geist Mono Typography
// .geist-text.h1 {
//   font-size: 3rem;
//   letter-spacing: -.066875rem;
//   font-weight: 700
// }

// .geist-text.h2 {
//   font-size: 2.25rem;
//   letter-spacing: -.049375rem;
//   font-weight: 600
// }

// .geist-text.h3 {
//   font-size: 1.5rem;
//   letter-spacing: -.029375rem;
//   font-weight: 600
// }

// .geist-text.h4 {
//   font-size: 1.25rem;
//   letter-spacing: -.020625rem;
//   font-weight: 600
// }

// .geist-text.h5 {
//   font-size: 1rem;
//   letter-spacing: -.01125rem;
//   font-weight: 600
// }

// .geist-text.h6 {
//   font-size: .875rem;
//   letter-spacing: -.005625rem;
//   font-weight: 600
// }

// .geist-text.p {
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.6
// }

// .geist-text.body-title {
//   font-size: 1rem;
//   font-weight: 600;
//   line-height: 1.4
// }

// .geist-text.body-1 {
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.6
// }

// .geist-text.body-2 {
//   font-size: .875rem;
//   font-weight: 400;
//   line-height: 1.6
// }

// .geist-text.small {
//   font-size: 14px;
//   font-weight: 400
// }

// .geist-text.label {
//   font-size: 12px;
//   font-weight: 500
// }

// .geist-text.w-100 {
//   font-weight: 100
// }

// .geist-text.w-200 {
//   font-weight: 200
// }

// .geist-text.w-300 {
//   font-weight: 300
// }

// .geist-text.w-400 {
//   font-weight: 400
// }

// .geist-text.w-500 {
//   font-weight: 500
// }

// .geist-text.w-600 {
//   font-weight: 600
// }

// .geist-text.w-700 {
//   font-weight: 700
// }

// .geist-text.w-800 {
//   font-weight: 800
// }

// .geist-text.w-900 {
//   font-weight: 900
// }



// Geist Bold Typography