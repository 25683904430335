//
// Extended from bootstrap
//
// Type scss
h1{
  letter-spacing: -.066875rem;
}
h2{
  letter-spacing: -.049375rem;
}
h3{
  letter-spacing: -.029375rem;
}
h4{
  letter-spacing: -.020625rem;
}
h5{
  letter-spacing: -.01125rem;
}
h6{
  letter-spacing: -.005625rem;
}
.h1{
  letter-spacing: -.066875rem;
}
.h2{
  letter-spacing: -.049375rem;
}
.h3 {
  letter-spacing: -.029375rem;
}
.h4 {
  letter-spacing: -.020625rem;
}
.h5 {
  letter-spacing: -.01125rem;
}
.h6 {
  letter-spacing: -.005625rem;
}

// Type display classes
.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: -0.066875rem;
}

// Lead
.lead {
  letter-spacing: -0.03rem;
}
